<template>
	<div class="bg-F7F8FA">
		<div class="relative">
			
			<el-image class="login_bg display-block w100"  :src="bgimg"  lazy></el-image>
			<div class="absolute left0 right0 top0 bottom0 center flex-column">
				<!-- <div class="fz46 co-white font-blod">关于我们</div> -->
				<!-- <div class="fz18 co-white mart22">我们的愿景与使命是把数字世界带入每个人、每个家庭、每个组织。</div> -->
			</div>
		</div>
		<div class=" bg-white paddt30">
			<div class="w1200 main bg-white display-flex align-items-center justify-content-center" style="margin: 0 auto;">
				<div class=" mart10 flex-column">
					<div v-html="pagedata.pc_about_us" class="html-box ql-editor content-detail">
					</div>
				</div>
			</div>
		</div>
		<div class="w100 paddb65"></div>
		<div class="bg-F7F8FA w100 center mart40 flex-column">
			<div class="w1200  paddt30 paddb30 display-flex align-items-center">
				<div class=" ">
					<div class="line-active"></div>
				</div>
				<span class="fz20 co-010085 font-blod paddl12 display-block">联系我们 & 商务合作</span>
				
			</div>
			<div class="w1200 display-flex paddb45">
				<div class="center flex-column marr30">
					<img class="ab_01 display-block" src="@/assets/img/ab_01.png" alt="" />
					<span class="fz18 co-333333 display-block mart14">{{join_us_phone}}</span>
				</div>
				<div class="center flex-column">
					<img class="ab_01 display-block" src="@/assets/img/ab_02.png" alt="" />
					<span class="fz18 co-333333 display-block mart14">{{join_us_wechat}}</span>
				</div>
				<!-- <div class="center flex-column">
					<img class="ab_01 display-block" src="@/assets/img/ab_03.png" alt="" />
					<span class="fz18 co-333333 display-block mart14">jianzhu@163.com</span>
				</div>
				<div class="center flex-column">
					<img class="ab_01 display-block" src="@/assets/img/ab_04.png" alt="" />
					<span class="fz18 co-333333 display-block mart14">jianzhu@163.com</span>
				</div>
				<div class="center flex-column">
					<img class="ab_01 display-block" src="@/assets/img/ab_05.png" alt="" />
					<span class="fz18 co-333333 display-block mart14">jianzhu@163.com</span>
				</div> -->
			</div>
		</div>
		<div class="w100 paddb65"></div>
		<div v-loading.fullscreen.lock="fullscreenLoading"></div>
	</div>
</template>

<script>
	export default {
		name: 'About',
		data() {
			return {
				fullscreenLoading: true,
				state: 1, // 列表加载状态
				page: 0, // 分页
				loading: false, // 接口加载状态
				list: [], // 列表
				pagedata: {},
				join_us_phone:"",
				join_us_wechat:"",
				bgimg:""
			}
		},
		mounted() {
			this.$req({
				method: 'get',
				url: '/api/config',
				data: {
					name: 'pc_about_us',
				},
				success: res => {
					console.log(res)
					this.pagedata = res
				},
				fail: error => {}
			});
			this.$req({
				method: 'get',
				url: '/api/config',
				data: {
					name: 'join_us_phone,join_us_wechat',
				},
				success: res => {
					this.join_us_phone= res.join_us_phone;
					this.join_us_wechat = res.join_us_wechat
					
				},
				fail: error => {}
			});
			this.$req({
				method: 'get',
				url: '/api/config',
				data: {
					name: 'about_us_banner_image',
				},
				success: res => {
					this.bgimg = res.about_us_banner_image;
					console.log(this.bgimg)
				},
				fail: error => {}
			});
			this.fullscreenLoading =false;
		},
		methods: {

		}
	}
</script>

<style scoped="scoped">
	.login_bg {
		width: 100%;
		height: 600px;
	}

	.html-box>>>img {

		display: block;
		margin: 5px auto;
		width: 100% !important;
		height: auto !important;
	}

	.html-box>>>p {

		width: 100%;
		word-break: break-word;
		font-size: 18px;
		font-weight: 500;
		color: #000000;
		margin: 30px 0;
	}

	.html-box>>>span {

		max-width: 100%;
		word-break: break-word;
		font-size: 18px;
		font-weight: 500;
		color: #000000;
		margin: 30px 0;
	}


	.advantage {
		height: 404.38px;
	}

	.line {
		bottom: -8px;


	}

	.line-active {
		width: 3px;
		height: 18px;
		background: #DCFF03;
		border-radius: 2px;
	}

	.content-01 {
		text-align: center;
	}

	.img_02 {
		width: 386px;
		height: 240px;
	}
</style>
